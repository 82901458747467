import { useState } from "react";

const questions = [
  {
    question: "What is the purpose of your AI image generation website?",
    answer:
      "Our website is designed for the creative realization of your ideas using cutting-edge artificial intelligence technologies.",
  },
  {
    question: "What capabilities does your service provide for users?",
    answer:
      "We provide unique tools for creating personalized and creative images using advanced artificial intelligence algorithms.",
  },
  {
    question: "What is the principle behind image generation on your website?",
    answer:
      "We use powerful deep learning algorithms to adapt to your preferences and create unique images that reflect your style.",
  },
  {
    question: "How can users interact with the AI generator on your website?",
    answer:
      'The process is simple: choose parameters that describe your future image and click "Create." We take care of the rest!',
  },
  {
    question:
      "How is the security and confidentiality of user data ensured when using your service?",
    answer:
      "We adhere to high security standards and ensure user anonymity. Your data remains confidential.",
  },
  {
    question:
      "What examples of using AI-generated images have found application in real life?",
    answer:
      "Our users use created images for illustrations, design, social media, and even for educational purposes.",
  },
  {
    question:
      "How does your service support diversity in styles and genres in the created images?",
    answer:
      "We constantly update our algorithms to expand the spectrum of styles and genres, from classNameical to modern art.",
  },
  {
    question:
      "How can users contribute to improving the functionality of your service?",
    answer:
      "We value user feedback! You can share your experiences, suggestions, and ideas through our feedback section.",
  },
];

export const Questions = () => {
  const [activeStates, setActiveStates] = useState(
    Array(questions.length).fill(false)
  );

  const handleToggle = (index) => {
    setActiveStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-white">
            Your questions, answered
          </h2>
          <p className="mt-1  text-gray-400">
            Answers to the most frequently asked questions.
          </p>
        </div>

        <div className="max-w-2xl mx-auto">
          <div className="hs-accordion-group">
            {questions.map((item, index) => (
              <div
                key={index}
                className={`hs-accordion rounded-xl p-6 ${
                  activeStates[index]
                    ? "hs-accordion-active:bg-white/[.05] active my-3"
                    : ""
                }`}
              >
                <button
                  className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition hover:text-gray-500 text-gray-200 dark:hover:text-gray-400"
                  onClick={() => handleToggle(index)}
                >
                  {item.question}
                  <svg
                    className={`${
                      activeStates[index] ? "transform rotate-180" : ""
                    } flex-shrink-0 w-5 h-5 group-hover:text-gray-500 text-gray-400`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </button>
                <div
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${
                    activeStates[index] ? "" : "hidden"
                  }`}
                >
                  <p className="text-gray-800" style={{ color: "#4467ff" }}>
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
