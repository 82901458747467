import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import { generationReducer } from "./slices/generation";
import { collectionReducer } from "./slices/collection";

const store = configureStore({
  reducer: {
    auth: authReducer,
    generation: generationReducer,
    collection: collectionReducer,
  },
});

export default store;
