import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { getImg } from "../redux/slices/generation";
import axios from "../axios";
import fileDownload from "js-file-download";
import { CirclesWithBar } from "react-loader-spinner";
import { Context } from "../Context";
import { addImgToCol, getCollection } from "../redux/slices/collection";
import { motion } from "framer-motion";

export const InputPrompt = () => {
  const dispatch = useDispatch();
  const [promptValue, setPromptValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const [error, setError] = React.useState("");
  const [widthImg, setWidthImg] = React.useState("512");
  const [heightImg, setHeightImg] = React.useState("512");
  const sizes = [
    { width: "128", height: "128" },
    { width: "256", height: "256" },
    { width: "512", height: "512" },
    { width: "1024", height: "1024" },
  ];
  const {
    userData,
    setUserData,
    setLinkImg,
    linkImg,
    addCollection,
    setAddCollection,
    setLinks,
    links,
    checkAddImg,
    setCheckAddImg,
    activationCheck,
    checkLoadingActivation,
  } = useContext(Context);
  const submitPrompt = async (promptValue) => {
    if (userData.prompts > 0) {
      setCheckAddImg(false);
      setLoading(true);
      const data = await dispatch(
        getImg({ prompt: promptValue, width: widthImg, height: heightImg })
      );
      if (data.payload) {
        setLinkImg(data.payload.answer);
        setError("");
        setUserData({
          ...userData,
          prompts: userData.prompts - 1,
        });
        setAddCollection(false);
      } else {
        setError("Error when generating image");
        setLinkImg("");
      }
      setLoading(false);
    } else {
      setError("You've run out of prompts.");
      setLinkImg("");
    }
  };
  const setSize = (width, height) => {
    setWidthImg(width);
    setHeightImg(height);
  };
  const handleDownload = (url) => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let filename = "";

    for (let i = 0; i < 10; i++) {
      if (i % 2 === 0) {
        const randomChar = characters.charAt(Math.floor(Math.random() * 26));
        filename += randomChar;
      } else {
        const randomDigit = characters.charAt(
          Math.floor(Math.random() * 10) + 26
        );
        filename += randomDigit;
      }
    }
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${filename}.png`);
      });
  };
  const addToCollection = async (linkImg) => {
    if (!addCollection && !checkAddImg) {
      setCheckAddImg(true);
      await setLinks([...links, linkImg]);
      await dispatch(addImgToCol({ link: linkImg }));
      setAddCollection(true);
      const data = await dispatch(getCollection());
      setLinks(data.payload.collection);
    }
  };

  const resetActivateMail = async () => {
    try {
      const data = await axios.post("/resetMailActivate", {
        email: userData.email,
      });
      if (data.status === 200) {
        setMailSent(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };

  if (checkLoadingActivation) {
    return (
      <section>
        <motion.div
          variants={container}
          animate="visible"
          initial="hidden"
          className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
        >
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 animate-spin text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </motion.div>
      </section>
    );
  }

  if (!activationCheck) {
    return (
      <motion.div
        variants={container}
        animate="visible"
        initial="hidden"
        className="flex shadow flex-col items-center justify-center  text-white p-4 h-screen rounded-md"
      >
        <div className="p-4 bg-gray-800 shadow-md rounded-md flex justify-center flex-col text-center items-center">
          <h1 className="text-lg">
            To use generation, you need to activate your account, follow the
            link in the letter we sent to your email. If you have activated your
            account and this message remains, try reloading the page.
          </h1>
          <p className="text-lg">
            if the letter never arrived, click reset to resend the letter
          </p>
          {mailSent && (
            <p className="text-green-500">
              The activation email has been resent to your email.
            </p>
          )}
          <button
            disabled={mailSent}
            onClick={resetActivateMail}
            className={`${
              mailSent ? "" : "cursor-pointer"
            } inline-flex text-white ${
              mailSent ? "bg-slate-400 btn-sign" : "bg-primary-600 btn-sign up"
            } hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4`}
          >
            Reset
          </button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      variants={container}
      animate="visible"
      initial="hidden"
      className="flex shadow flex-col items-center justify-center h-screen text-white"
    >
      <div className="p-4  shadow-md rounded-md flex justify-center">
        {loading ? (
          <CirclesWithBar
            height="100"
            width="100"
            color="#4467ff"
            visible={true}
            ariaLabel="circles-with-bar-loading"
          />
        ) : (
          <>
            {linkImg ? (
              <img
                src={linkImg}
                alt="AI Generated"
                className="w-96 md:w-3/4  object-cover rounded-lg"
                onLoad={() => setLoading(false)}
              />
            ) : error ? (
              <h1 className="text-red-500">{error}</h1>
            ) : (
              <h1 className="text-2xl text-center">
                You need to enter a prompt
              </h1>
            )}
          </>
        )}
      </div>
      <div className="w-full lg:w-7/12 p-4 bg-gray-800 shadow-md rounded-md">
        <p>Remaining prompts: {userData.prompts}</p>
        <input
          type="text"
          minLength={3}
          className="w-full p-2 border bg-gray-800 mt-4 rounded-md text-white placeholder-gray-300 focus:outline-none"
          placeholder="Enter a prompt"
          value={promptValue}
          onChange={(e) => setPromptValue(e.target.value)}
        />
        <div className="flex items-center mt-2">
          <div className="flex flex-col mr-2">
            <p className="text-sm font-medium text-white">Width:</p>
            <input
              value={widthImg}
              className="w-full p-2 border bg-gray-800 rounded-md font-medium text-white placeholder-gray-300 focus:outline-none"
              onChange={(e) => setWidthImg(e.target.value)}
              type="number"
              placeholder="default 512"
              min={128}
              max={1536}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-medium text-white">Height:</p>
            <input
              value={heightImg}
              className="w-full p-2 border bg-gray-800 rounded-md font-medium text-white placeholder-gray-300 focus:outline-none"
              onChange={(e) => setHeightImg(e.target.value)}
              type="number"
              placeholder="default 512"
              min={128}
              max={1536}
            />
          </div>
          <div className="flex flex-col ml-2">
            <label className="text-sm font-medium text-white">
              Сhoose size
            </label>
            <select
              className="w-full border  text-sm rounded-lg focus:outline-none p-2 font-medium bg-gray-800  placeholder-gray-300 text-white"
              onChange={(e) => {
                const selectedSize = sizes[e.target.selectedIndex];
                setSize(selectedSize.width, selectedSize.height);
              }}
            >
              {sizes.map((item, index) => (
                <option
                  key={index}
                  selected={item.width === "512" && item.height === "512"}
                >
                  {item.width}x{item.height}
                </option>
              ))}
            </select>
          </div>
        </div>
        <a
          className="block w-full mt-2 mb-4 p-2 btn-sign up text-white rounded-md text-center cursor-pointer"
          onClick={() => {
            if (promptValue.length > 2) {
              submitPrompt(promptValue);
            }
          }}
        >
          Submit
        </a>
        {!loading && linkImg && (
          <>
            <a
              className={`block w-full mt-2 mb-4 p-2  ${
                !checkAddImg ? "btn-sign up cursor-pointer" : ""
              } text-white rounded-md text-center`}
              onClick={() => addToCollection(linkImg)}
            >
              {!checkAddImg ? "Add to collection" : "Added to collection"}
            </a>

            <a
              onClick={() => handleDownload(linkImg)}
              className="cursor-pointer w-full p-2 btn-sign up bg-gray-800 text-white rounded-md text-center block"
            >
              Download Image
            </a>
          </>
        )}
      </div>
    </motion.div>
  );
};
