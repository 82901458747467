import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCollection, removeImgFromCol } from "../redux/slices/collection";
import { Context } from "../Context";
import axios from "axios";

export const Collection = () => {
  const dispatch = useDispatch();
  const { links, setLinks, setAddCollection } = useContext(Context);

  const getLinks = async () => {
    try {
      const response = await dispatch(getCollection());
      setLinks(response.payload.collection);
    } catch (error) {
      console.error("Ошибка получения коллекции:", error);
    }
  };

  const handleDownload = async (name) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LINK_API_BACKEND}downloadImage/${name}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка загрузки изображения:", error);
    }
  };

  const handleDelete = async (link) => {
    try {
      const response = await dispatch(removeImgFromCol({ link: link }));
      setLinks(response.payload.collection);
      setAddCollection(false);
    } catch (error) {
      console.error("Ошибка удаления изображения:", error);
    }
  };

  useEffect(() => {
    getLinks();
  }, []);

  return (
    <div>
      {links.length > 0 ? (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-20">
          {links.map((image, index) => (
            <div key={index} className="flex flex-col items-center">
              <img
                src={`${process.env.REACT_APP_LINK_SITE}images/${image}`}
                alt="placeholder"
                className="object-cover w-full rounded-lg"
              />
              <div className="flex flex-wrap">
                <a
                  onClick={() => handleDownload(image)}
                  className="btn-download text-white font-bold py-2 px-4 rounded mr-2 mt-2 cursor-pointer"
                >
                  Download
                </a>
                <a
                  onClick={() => handleDelete(image)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2 cursor-pointer"
                >
                  Delete
                </a>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 bg-gray-800 text-white rounded-md text-center mt-20">
          <h1 className="text-lg">Your collection is empty</h1>
        </div>
      )}
    </div>
  );
};
