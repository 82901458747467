import { motion } from "framer-motion";
import { useContext } from "react";
import { Context } from "../Context";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.15,
    },
  },
};

export const Transactions = () => {
  const { userData } = useContext(Context);
  const { paymentHistory } = userData;
  return (
    <motion.div
      variants={container}
      animate="visible"
      initial="hidden"
      className="p-4  text-white mt-10 shadow-md rounded-md"
    >
      <div className="p-4 bg-gray-800  rounded-lg">
        <h2 className="text-xl font-bold mb-2">Transaction history</h2>
        <div className="space-y-2">
          {paymentHistory && paymentHistory.length > 0 ? (
            paymentHistory.map((item, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <span className="text-gray-300 mr-3">#{index + 1}</span>
                  <span className="text-gray-500">{item.timestamp}</span>
                  <span
                    className={
                      item.amount > 0 ? "text-green-600" : "text-red-600"
                    }
                  >
                    {item.amount > 0 ? "+" : "-"}
                    {Math.abs(item.amount)}$
                  </span>
                </div>
              );
            })
          ) : (
            <h1>History empty</h1>
          )}
        </div>
      </div>
    </motion.div>
  );
};
