// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-logout {
  border: 1.5px solid #4467ff;
  padding: 5px 20px;
  border-radius: 40px;
  background: #4467ff;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  cursor: pointer;
}
.btn-download {
  background: #4467ff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/NavBar.css"],"names":[],"mappings":"AAAA;EAEE,2BAA2B;EAC3B,iBAAiB;EAKjB,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".btn-logout {\n  border-radius: 40px;\n  border: 1.5px solid #4467ff;\n  padding: 5px 20px;\n  -webkit-border-radius: 40px;\n  -moz-border-radius: 40px;\n  -ms-border-radius: 40px;\n  -o-border-radius: 40px;\n  border-radius: 40px;\n  background: #4467ff;\n  -webkit-border-radius: 40px;\n  -moz-border-radius: 40px;\n  -ms-border-radius: 40px;\n  -o-border-radius: 40px;\n  cursor: pointer;\n}\n.btn-download {\n  background: #4467ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
