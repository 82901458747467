import { InputPrompt } from "../components/InputPrompt";
import { NavBar } from "../components/NavBar";
import { fetchLogin, isAuthSelector } from "../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Route, Routes } from "react-router-dom";
import { Profile } from "./Profile";
import { Support } from "./Support";
import React, { useEffect } from "react";
import { TopUp } from "./TopUp";
import { Context } from "../Context";
import { Collection } from "./Collection";
import { Transactions } from "./Transactions";
import Cookies from "js-cookie";

export const Dashboard = () => {
  const checkAuth = useSelector(isAuthSelector);
  const navigate = useNavigate();
  useEffect(() => {
    const webToken = Cookies.get("webToken");
    if (!webToken) {
      navigate("/Login");
    }
    Cookies.remove("email");
    Cookies.remove("password");
  }, [checkAuth]);
  return (
    <>
      <NavBar />
      <main className="p-4 md:ml-64 h-auto pt-8">
        <Routes>
          <Route path="Profile" element={<Profile />} />
          <Route path="generation" element={<InputPrompt />} />
          <Route path="support" element={<Support />} />
          <Route path="TopUp" element={<TopUp />} />
          <Route path="collection" element={<Collection />} />
          <Route path="transactions" element={<Transactions />} />
        </Routes>
      </main>
    </>
  );
};
