import { motion } from "framer-motion";

export const Support = () => {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      animate="visible"
      initial="hidden"
      className="p-4 bg-gray-800 text-white mt-10 shadow-md rounded-md"
    >
      <h2 className="text-xl font-bold ">Technical support</h2>
      <p className="mt-2 ">
        If you have any problems, please contact our service support.
      </p>
      <div className="mt-4">
        <span className="font-bold ">email:</span>
        <span className="ml-2 text-gray-400">support@gmail.com</span>
      </div>
      <div className="mt-2">
        <span className="font-bold ">Phone:</span>
        <span className="ml-2 text-gray-400">+1 (234) 567-890</span>
      </div>
    </motion.div>
  );
};
