import React, { useContext } from "react";
import { Context } from "../Context";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePass, deleteAccount, logout } from "../redux/slices/auth";
import { motion } from "framer-motion";
import Cookies from "js-cookie";

export const Profile = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { userData } = useContext(Context);
  const [emailUser, setemailUser] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordPopUp, setPasswordPopUp] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const navigate = useNavigate();
  const [error, setError] = React.useState({
    value: "",
    color: "",
  });
  const [errorDelAcc, setErrorDelAcc] = React.useState({
    value: "",
    color: "",
  });

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };
  const textAnimation = {
    hidden: {
      y: -100,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  };

  const dispatch = useDispatch();
  const changePassword = async () => {
    try {
      if (newPassword === confirmPass) {
        const data = await dispatch(
          changePass({
            email: emailUser,
            password,
            newPassword,
          })
        );
        if (data.payload) {
          setError({ value: data.payload.msg, color: "text-green-500" });
        } else {
          setError({
            value: "The password you entered does not match the current one.",
            color: "text-red-600",
          });
        }
      } else {
        setError({
          value: "Password mismatch.",
          color: "text-red-600",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAcc = async () => {
    try {
      const data = await dispatch(deleteAccount({ password: passwordPopUp }));
      console.log(data);
      if (data.payload) {
        dispatch(logout());
        Cookies.remove("webToken");
        navigate("/Login");
      } else {
        setErrorDelAcc({
          value: "Password mismatch.",
          color: "text-red-600",
        });
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    setemailUser(userData.email);
  }, []);
  return (
    <motion.div variants={container} animate="visible" initial="hidden">
      <div className="p-4 shadow bg-gray-800 rounded-lg mt-20 text-white">
        <h2 className="text-xl font-bold mb-4">User Settings</h2>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            email
          </label>
          <input
            className="shadow appearance-none focus:border rounded w-full py-2 px-3 text-gray-200  bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder={emailUser}
            disabled
          />
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-200 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="********"
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            New password
          </label>
          <input
            className="shadow appearance-none rounded w-full py-2 px-3 text-gray-200 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="********"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Confirm password
          </label>
          <input
            className="shadow appearance-none rounded w-full py-2 px-3 text-gray-200 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="********"
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
          />
        </div>
        {<p className={`${error.color} mb-2`}>{error.value}</p>}
        <button
          className="btn-sign up  text-gray-200 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={changePassword}
        >
          Change password
        </button>
        <button
          onClick={() => {
            setShowModal(true);
          }}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full mt-2"
        >
          Delete account
        </button>
      </div>
      {showModal ? (
        <>
          <motion.div
            custom={3}
            variants={textAnimation}
            viewport={{ once: true }}
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-800 outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-solid  rounded-t">
                  <h3 className="text-3xl font-semibold text-red-600">
                    Delete account
                  </h3>
                  <a
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </a>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-white text-lg leading-relaxed">
                    You want to delete your account, after which it can no
                    longer be restored
                  </p>
                  <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2">
                      Enter your password to confirm
                    </label>
                    <input
                      className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-200 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      type="password"
                      value={passwordPopUp}
                      onChange={(e) => setPasswordPopUp(e.target.value)}
                      placeholder="********"
                    />
                    {
                      <p className={`${errorDelAcc.color} mb-2`}>
                        {errorDelAcc.value}
                      </p>
                    }
                  </div>
                </div>

                <div className="flex items-center justify-end p-6  border-solid rounded-b">
                  <a
                    className="text-white cursor-pointer background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </a>
                  <a
                    className="bg-red-600 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      deleteAcc();
                    }}
                  >
                    Yes
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      ) : null}
    </motion.div>
  );
};
