import React, { useContext, useEffect } from "react";
import { Price } from "../components/Price";
import { Context } from "../Context";
import axios from "../axios";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";

export const TopUp = () => {
  const { userData } = useContext(Context);
  const [valueTokens, setValueTokens] = React.useState("");
  const [checkInput, setCheckInput] = React.useState(false);
  const dispatch = useDispatch();
  const payPalPayment = async () => {
    try {
      if (!valueTokens) {
        setCheckInput(true);
        return;
      }
      if (valueTokens > 0) {
        const { data } = await axios.post(
          `${process.env.REACT_APP_LINK_API_BACKEND}paypal/create-payment`,
          {
            price: valueTokens,
            email: userData.email,
          }
        );
        const { url } = data;
        console.log(url);
        window.location = url;
      }
    } catch (error) {}
  };
  const checkoutStripe = async () => {
    try {
      if (!valueTokens) {
        setCheckInput(true);
        return;
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_LINK_API_BACKEND}create-checkout-session`,
        {
          items: [
            {
              id: 1,
              quantity: 1,
              price: valueTokens,
              name: "Top up your balance on flxart",
            },
          ],
          email: userData.email,
        }
      );
      const { url } = data;
      console.log(url);
      window.location = url;
    } catch (error) {
      console.log(error);
    }
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <motion.div variants={container} animate="visible" initial="hidden">
      <div className="flex space-y-3 md:space-y-0 flex-col md:flex-row md:justify-around md:space-x-5 mt-10 w-full">
        <a className="transform  hover:scale-105 transition duration-300 shadow-xl w-full rounded-lg col-span-12 sm:col-span-6 md:col-span-3 intro-y bg-gray-800">
          <div className="p-5">
            <div className="flex justify-between">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                ></path>
              </svg>
            </div>
            <div className="ml-2 w-full flex-1">
              <div>
                <div className="mt-3 text-3xl  font-semibold leading-8 text-white">
                  {userData.prompts}
                </div>
                <div className="mt-1 text-base  text-gray-300">
                  Prompts call remaining
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <Price />
    </motion.div>
  );
};
