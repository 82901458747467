import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_LINK_API_BACKEND,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = Cookies.get("webToken");
  return config;
});

export default instance;
