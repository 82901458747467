import React, { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { fetchRegistr, isAuthSelector } from "../redux/slices/auth";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../Context";
import { motion } from "framer-motion";

export const SignUp = () => {
  const dispatch = useDispatch();
  const checkAuth = useSelector(isAuthSelector);
  const { setUserData } = useContext(Context);
  const [email, setemail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const handleemailChange = (e) => {
    setError("");
    setemail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  if (checkAuth) {
    return <Navigate to="/Dashboard/generation" />;
  }

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const handleRegister = async (email, password) => {
    if (password.length < 5) {
      setError("Password should be at least 5 characters long.");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email address.");
      return;
    }

    try {
      setLoader(false);
      const data = await dispatch(fetchRegistr({ email, password }));
      if (!data.payload) {
        setLoader(true);
        setError("Failed to registration");
        if (data.error.message === "Request failed with status code 400") {
          setError("A user with the same email address already exists");
        }
      }
      if (data.payload.webToken) {
        Cookies.set("webToken", data.payload.webToken, {
          expires: 7,
          path: "/",
        });
        const { _id, email, prompts } = data.payload;
        setUserData({ _id, email, prompts });
        <Navigate to="/Dashboard/generation" />;
      }
    } catch (error) {
      setLoader(true);
    }
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <section>
      {loader ? (
        <motion.div
          variants={container}
          animate="visible"
          initial="hidden"
          className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
        >
          <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <motion.h1
                variants={item}
                className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white"
              >
                Create account
              </motion.h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <motion.label
                    variants={item}
                    className="block mb-2 text-sm font-medium  text-white"
                  >
                    Your email
                  </motion.label>
                  <motion.input
                    variants={item}
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={handleemailChange}
                    className="border-none outline-none sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <motion.label
                    variants={item}
                    className="block mb-2 text-sm font-medium text-white"
                  >
                    Password
                  </motion.label>
                  <motion.input
                    variants={item}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="border-none outline-none sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <motion.a
                  variants={item}
                  onClick={() => handleRegister(email, password)}
                  className="btn-sign cursor-pointer up block w-full hover-bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover-bg-primary-700 focus:ring-primary-800"
                >
                  Create an account
                </motion.a>
                {error && (
                  <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
                )}
                <motion.p
                  variants={item}
                  className="text-sm font-light  text-gray-400"
                >
                  Already have an account?
                  <Link
                    to="/Login"
                    className="font-medium text-primary-600 hover:underline text-primary-500 ml-2"
                  >
                    Login here
                  </Link>
                </motion.p>
              </form>
            </div>
          </div>
          <motion.div variants={item}>
            <Link
              to="/"
              className="inline-flex btn-sign up text-white bg-primary-600 hover-bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4"
            >
              Homepage
            </Link>
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          variants={container}
          animate="visible"
          initial="hidden"
          className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
        >
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 animate-spin text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </motion.div>
      )}
    </section>
  );
};
