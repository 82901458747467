import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../redux/slices/auth";
import { Context } from "../Context";
import { Info } from "./Info";
import { Questions } from "../components/Questions";

const textAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

const infoPrice = [
  {
    packageName: "Free Package",
    price: "0",
    amount: "10 prompts",
    descr: "Get started with our Free Plan and explore the basics",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
  ,
  {
    packageName: "Basic Package",
    price: "11.99",
    amount: "100 prompts",
    descr: "Our $11.99 package offers essential features for those on a budget",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
  {
    packageName: "Intermediate Package",
    price: "29.99",
    amount: "300 prompts",
    descr:
      "Upgrade to our $29.99 package and unlock advanced tools and resources",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
  {
    packageName: "Advanced Package",
    price: "54.99",
    amount: "600 prompts",
    descr:
      "Experience premium performance and features with our $54.99 package",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
  {
    packageName: "Max Package",
    price: "109.99",
    amount: "1200 prompts",
    descr: "Unlock the ultimate experience with our $109.99 package",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
  {
    packageName: "Vip Package",
    price: "199.99",
    amount: "2000 prompts",
    descr: "Experience unparalleled excellence with our $199.00 package",
    // privilege: ["100 prompt", "Lorem, ipsum."],
  },
];

export const Lending = () => {
  const checkAuth = useSelector(isAuthSelector);
  const { setPaymentSum } = React.useContext(Context);

  return (
    <>
      <Header />
      <main className="container">
        <section className="pt-10 lg:pt-[60px] pb-12 lg:pb-[90px] overflow-hidden">
          <div className="container baner-lending">
            <div className="flex flex-wrap items-center justify-between flex-row md:flex-row-reverse -mx-4">
              <div className="w-full px-4 lg:w-6/12">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="flex items-center -mx-3 sm:-mx-4"
                >
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="py-3 sm:py-4">
                      <motion.img
                        custom={3}
                        variants={textAnimation}
                        viewport={{ once: true }}
                        src="img/AI-img.png"
                        alt=""
                        className="w-full rounded-2xl"
                      />
                    </div>
                    <div className="py-3 sm:py-4">
                      <motion.img
                        custom={2}
                        viewport={{ once: true }}
                        variants={textAnimation}
                        src="img/AI-img-2.png"
                        alt=""
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="relative z-10 my-4">
                      <motion.img
                        custom={1}
                        viewport={{ once: true }}
                        variants={textAnimation}
                        src="img/AI-img-3.png"
                        alt=""
                        className="w-full rounded-2xl"
                      />
                      <span className="absolute -right-7 -bottom-7 z-[-1]"></span>
                    </div>
                  </div>
                </motion.div>
              </div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="w-full px-4 lg:w-1/2 xl:w-5/12"
              >
                <div className="mt-10 ml-5 lg:mt-0">
                  <motion.span
                    custom={1}
                    variants={textAnimation}
                    className="block sub-title mb-2 text-lg font-semibold text-primary"
                  >
                    Join us
                  </motion.span>
                  <motion.h2
                    custom={2}
                    variants={textAnimation}
                    className="mb-8 text-6xl font-bold title sm:text-7xl"
                  >
                    FlxArt: Unlock your potential as an artist
                  </motion.h2>
                  <motion.p
                    custom={3}
                    variants={textAnimation}
                    className="mb-8 text-base text-body-color"
                  >
                    Unlock your potential as an artist with every click. Create
                    works of art effortlessly with just one click
                  </motion.p>
                  <motion.div
                    custom={4}
                    variants={textAnimation}
                    className="flex space-x-4"
                  >
                    <Link to="/SignUp" className="btn-sign up">
                      Get started
                    </Link>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
        <Info />
        <section id="Collection" className="collection">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2, once: true }}
          >
            <motion.h1 variants={textAnimation} className="text-center">
              Collection
            </motion.h1>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10"
          >
            <div className="grid gap-4">
              <div>
                <motion.img
                  custom={1}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art1.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={2}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art2.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={3}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art3.png"
                  alt=""
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <motion.img
                  custom={4}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art4.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={5}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art5.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={6}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art6.png"
                  alt=""
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <motion.img
                  custom={7}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art7.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={8}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art8.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={9}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art9.png"
                  alt=""
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <motion.img
                  custom={10}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art10.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={11}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art11.png"
                  alt=""
                />
              </div>
              <div>
                <motion.img
                  custom={12}
                  variants={textAnimation}
                  className="h-auto max-w-full rounded-lg"
                  src="img/art12.png"
                  alt=""
                />
              </div>
            </div>
          </motion.div>
        </section>
        <section id="questions" className="questions">
          <Questions />
        </section>
        <section id="Pricing" className="price mt-9">
          <h1 className="text-center">Buy prompts</h1>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6"
          >
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10  lg:space-y-0">
              {infoPrice.map((item, index) => (
                <motion.div
		     key={index}
                  custom={index}
                  variants={textAnimation}
                  className="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow border-gray-600 xl:p-8 bg-gray-800 text-white"
                >
                  <h3 className="mb-4 text-2xl font-semibold">
                    {item.packageName}
                  </h3>
                  <p className="font-light  sm:text-lg text-gray-400">
                    {item.descr}
                  </p>
                  <div className="flex justify-center items-baseline my-8">
                    <span className="mr-2 text-5xl font-extrabold">
                      ${item.price}
                    </span>
                  </div>
                  <ul role="list" className="mb-8 space-y-4 text-left">
                    <li className="flex items-center space-x-3 justify-center">
                      <svg
                        className="flex-shrink-0 w-5 h-5  text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="text-lg">{item.amount}</span>
                    </li>
                  </ul>

                  {index != 0 ? (
                    <Link
                      onClick={() => setPaymentSum(index)}
                      to={checkAuth ? "dashboard/profile" : "payment"}
                      className=" btn-sign up bg-primary-600 cursor-pointer hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                    >
                      Get started
                    </Link>
                  ) : null}
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>
      </main>
      <Footer />
    </>
  );
};
