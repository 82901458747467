import { Link } from "react-router-dom";
import axios from "../axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Context } from "../Context";
import Cookies from "js-cookie";
import { fetchAuth } from "../redux/slices/auth";

export const NoAccSuccessPaypal = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { setUserData } = React.useContext(Context);
  const searchParams = new URLSearchParams(window.location.search);
  const paymentId = searchParams.get("paymentId");
  const payerID = searchParams.get("PayerID");
  const checkPay = async () => {
    try {
      const data = await dispatch(
        fetchAuth({
          email: Cookies.get("email"),
          password: Cookies.get("password"),
        })
      );
      if (data.payload) {
        Cookies.set("webToken", data.payload.webToken, {
          expires: 7,
          path: "/",
        });
        const { email, prompts, paymentHistory, activation } = data.payload;
        setUserData({ email, prompts, paymentHistory, activation });
        axios.post(
          `${process.env.REACT_APP_LINK_API_BACKEND}newprofile/paypal/success`,
          {
            paymentId,
            payerID,
            email,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setEmail(Cookies.get("email"));
    setPassword(Cookies.get("password"));
    checkPay();
  }, []);

  return (
    <>
      <div
        className="relative z-10 "
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-600 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 12.75L11.25 15L15 9.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      className="text-base font-semibold leading-6  text-white"
                      id="modal-title"
                    >
                      Payment successful
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        The payment has been made, the funds will be credited to
                        your account, if the money is not credited within 12
                        hours, write to technical support.
                      </p>
                    </div>
                    <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                      <h3
                        className="text-base font-semibold leading-6  text-white"
                        id="modal-title"
                      >
                        Your data for subsequent authorization:
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-300">email:{email}</p>
                        <p className="text-sm text-red-600 mt-1">
                          password:{password}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <Link
                  to="/Dashboard/generation"
                  onClick={async () => {
                    try {
                      const data = await dispatch(
                        fetchAuth({
                          email: Cookies.get("email"),
                          password: Cookies.get("password"),
                        })
                      );
                      if (data.payload) {
                        Cookies.set("webToken", data.payload.webToken, {
                          expires: 7,
                          path: "/",
                        });
                        const { email, prompts, paymentHistory } = data.payload;
                        setUserData({ email, prompts, paymentHistory });
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto mb-5"
                >
                  Good
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
