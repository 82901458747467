import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../redux/slices/auth";
import { motion } from "framer-motion";
import axios from "axios";

export const ResetPasswordForm = () => {
  const checkAuth = useSelector(isAuthSelector);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [navigateToLogin, setNavigateToLogin] = useState(false);

  const ResetPassword = async () => {
    if (!newPassword || !confirmPassword) {
      setError("Please enter both new and confirm passwords.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (newPassword.length < 5) {
      setError("Password should be at least 5 characters long.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LINK_API_BACKEND}reset_password`,
        {
          token,
          newPassword,
        }
      );
      setNewPassword("");
      setConfirmPassword("");
      if (response.data.msg === "Invalid or expired recovery token") {
        setError("Time limit for recovery has expired.");
        setNavigateToLogin(true);
      }
      if (response.data.msg === "Password reset successful") {
        setSuccessMessage("Password reset successfully.");
        setNavigateToLogin(true);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      setError("Failed to reset password. Please try again later.");
    }
  };

  useEffect(() => {
    if (navigateToLogin) {
      const timer = setTimeout(() => {
        navigate("/Login");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [navigateToLogin]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  if (checkAuth) {
    return <Navigate to="/Dashboard/generation" />;
  }
  if (!token) {
    return <Navigate to="/" />;
  }
  return (
    <section>
      <motion.div
        variants={container}
        animate="visible"
        initial="hidden"
        className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
      >
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <motion.h1
              variants={item}
              className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white"
            >
              Password recovery
            </motion.h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <motion.label
                  htmlFor="password"
                  variants={item}
                  className="block mb-2 text-sm font-medium text-white"
                >
                  New Password
                </motion.label>
                <motion.input
                  type="password"
                  variants={item}
                  placeholder="Password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setError("");
                  }}
                  className="border-none outline-none  sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <motion.label
                  variants={item}
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Repeat password
                </motion.label>
                <motion.input
                  type="password"
                  variants={item}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError("");
                  }}
                  className="border-none outline-none text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <motion.a
                variants={item}
                onClick={() => ResetPassword()}
                className="btn-sign up block cursor-pointer w-full hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
              >
                Reset Password
              </motion.a>
              {error && (
                <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
              )}
              {successMessage && (
                <div style={{ color: "green", marginTop: "10px" }}>
                  {successMessage}
                </div>
              )}
            </form>
          </div>
        </div>
        <motion.div variants={item}>
          <Link
            to="/"
            className="inline-flex btn-sign up text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4"
          >
            Homepage
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};
