import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const addImgToCol = createAsyncThunk(
  "auth/addImgToCol",
  async (params) => {
    const { data } = await axios.post("/addImgToCol", { ...params });
    return data;
  }
);

export const getCollection = createAsyncThunk(
  "auth/getCollection",
  async () => {
    const { data } = await axios.get("/getcollection");
    return data;
  }
);

export const removeImgFromCol = createAsyncThunk(
  "auth/removeImgFromCol",
  async (params) => {
    const { data } = await axios.post("/removeImgFromCol", params);
    return data;
  }
);

const initialState = {
  data: null,
  status: "loading",
};

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  //   reducers: {

  //   },
  extraReducers: {
    [addImgToCol.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [addImgToCol.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [addImgToCol.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
    [getCollection.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [getCollection.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [getCollection.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
    [removeImgFromCol.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [removeImgFromCol.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [removeImgFromCol.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
  },
});

export const collectionReducer = collectionSlice.reducer;
