import { Link } from "react-router-dom";

export const Policy = () => {
  return (
    <>
      <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
        <div className="grid gap-4 md:gap-8">
          <h1 className="text-2xl font-bold md:text-4xl text-white">
            PRIVACY POLICY
          </h1>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              Our Privacy Policy
            </h2>

            <p className="mb-5 text-gray-400">
              Your privacy is important to us, so FLXART, a company based in the
              GB, has created the following Privacy Policy ("Policy") to let you
              know what information we collect when you visit our Site
              https://getflxart.com/ ("Site"), why we collect it, and how we use
              it. The terms "You," "Your," "Yours," and "User" refer to the
              entity/person/organization using our Site. When this Policy
              mentions "We," "Us," and "Our," it refers to [business name UK]
              and its subsidiaries and affiliates. This Privacy Policy is
              governed by our Terms of Services. For any questions regarding
              this Policy or any requests regarding the processing of personal
              data, please contact us at flxart@gmail.com.
            </p>
          </div>

          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              1. INFORMATION WE COLLECT FROM YOU
            </h2>

            <p className="text-gray-400">
              We collect the information You provide to us, and this information
              is necessary for the adequate performance of the contractual
              arrangement which is in place between You and us and allows us to
              comply with our legal obligations.
            </p>
            <p className="text-gray-400">
              Account Signup Information: When You create the account, we ask
              You to provide the signup information, such as Email, Name,
              Passwords.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              2. INFORMATION WE COLLECT AUTOMATICALLY
            </h2>

            <p className="text-gray-400">
              When you use our Site or contact us directly, we may collect
              information, including your personal information, about the way
              you act on our Site, the services You use, and how You use them.
              This information is necessary for the adequate performance of the
              contract between You and us, to enable us to comply with legal
              obligations, and given our legitimate interest in being able to
              provide and improve the functionalities of the Site.
            </p>
            <p className="text-gray-400">
              Log data and Device information: We automatically collect log data
              and device information when you access and use the Site, even if
              you have not created an Account or logged in. That information
              includes, among other things: Internet protocol (IP) addresses,
              Internet service provider (ISP), Referring/exit pages, Operating
              system, Date/time stamp.
            </p>
            <p className="text-gray-400">
              Tracking technologies and Cookies: We use Cookies, Phone model. We
              also automatically collect information about the device’s
              operating system.
            </p>
            <p className="text-gray-400">
              Usage information: We use a tool called "Google Analytics" to
              collect information about your interactions with the Site (what
              pages you visit, such as the pages or content you view, your
              searches for Listings, bookings you have made, and other actions
              on the Site. In consequence, Google, Inc. plants a permanent
              cookie on your web browser to identify you as a unique user the
              next time you visit this Site). For more information please visit
              Google.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              3. THE WAY WE USE YOUR INFORMATION
            </h2>
            <p className="text-gray-400">
              We process your information adhering to the general data
              processing principles. We may use the information we collect
              through our Site for a number of reasons, including:
            </p>
            <ul className="list-disc ml-5 text-white">
              <li>to identify user</li>
              <li>to create account</li>
              <li>to send billing information</li>
              <li>to manage user orders</li>
              <li>to contact user</li>
              <li>to improve services</li>
              <li>to ensure data security and prevent fraud</li>
              <li>to comply with applicable laws</li>
              <li>to request feedback</li>
              <li>to provide support</li>
            </ul>
            <p className="text-gray-400">
              We will normally collect personal information from you only where
              we have your consent to do so, where we need the personal
              information to perform a contract with you, or where the
              processing is in our legitimate business interests.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              4. COOKIES
            </h2>
            <p className="text-gray-400">
              Cookies are small text files stored by your browser on your
              computer when you visit our Site. We use cookies to improve our
              Site and make it easier to use. Cookies permit us to recognize
              users and avoid repetitive requests for the same information.
            </p>
            <p className="text-gray-400">
              Cookies from our Site cannot be read by other Sites. Most browsers
              will accept cookies unless you change your browser settings to
              refuse them.
            </p>
            <p className="text-gray-400">Cookies we use on our Site:</p>
            <ul className="list-disc ml-5 text-white">
              <li>
                Strictly necessary cookies: These cookies are required for the
                operation of our Site. They help us show you the right
                information, customize your experience, and allow us to
                implement and maintain security features as well as help us
                detect malicious activities. Without these cookies, the
                operation of the Website would be impossible or its functioning
                may be severely affected.
              </li>
            </ul>
            <p className="text-gray-400">
              You may find more information about how to delete cookies, as well
              as other useful information related to the use of cookies, on the
              website http://www.allaboutcookies.org/.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              5. SENSITIVE INFORMATION
            </h2>
            <p className="text-gray-400">
              We do not collect sensitive information such as political
              opinions, religious or philosophical beliefs, racial or ethnic
              origin, genetic data, biometric data, health data, or data related
              to sexual orientation.
            </p>
            <p className="text-gray-400">
              Please do not send, upload, or provide us with any sensitive data
              and contact us using the contact details below if you believe that
              we might have such information. We have the right to delete any
              information we believe might contain sensitive data.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              6. PAYMENT INFORMATION
            </h2>
            <p className="text-gray-400">
              To order and use our services, we may require you to provide
              certain financial information to facilitate the processing of
              payments. We will not store or collect your payment card details.
              That information is provided directly to our third-party payment
              processors whose use of your Personal information is governed by
              their Privacy Policy. All payment data is stored by Paypal,
              Stripe. You may find their privacy policy link(s) here: Paypal
              Stripe.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              7. THIRD PARTY LINKS
            </h2>
            <p className="text-gray-400">
              Our Site may have links to other websites. Please review their
              privacy policies to learn more about how they collect and use your
              personal data because we do not control their policies and
              personal data processing practices.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              8. RETENTION
            </h2>
            <p className="text-gray-400">
              We retain your personal information to provide services to you and
              as otherwise necessary to comply with our legal obligation,
              resolve disputes, and enforce our agreements.
            </p>
            <p className="text-gray-400">
              We will retain your personal information not longer than you keep
              your account, unless we are otherwise required by law or
              regulations to retain your personal information longer.
            </p>
            <p className="text-gray-400">
              If you would like to stop us using your personal information, you
              shall request that we erase your personal information and close
              your Account. Please note that even after deletion of your account
              we may keep some of your information for tax, legal reporting, and
              auditing obligations.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              9. SECURITY
            </h2>
            <p className="text-gray-400">
              We have implemented security measures designed to protect the
              personal information you share with us, including physical,
              electronic, and procedural measures. Among other things, we
              regularly monitor our systems for possible vulnerabilities and
              attacks.
            </p>
            <p className="text-gray-400">
              Regardless of the measures and efforts taken by us, the
              transmission of information via internet, email, or text message
              is not completely secure. We do not guarantee the absolute
              protection and security of your personal information.
            </p>
            <p className="text-gray-400">
              We, therefore, encourage you to avoid providing us or anyone with
              any sensitive information of which you believe its disclosure
              could cause you substantial or irreparable harm.
            </p>
            <p className="text-gray-400">
              If you have any questions regarding the security of our Site or
              Services, you are welcome to contact us at contact@getflxart.com.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              10. YOUR RIGHTS
            </h2>
            <p className="text-gray-400">
              You are entitled to a range of rights regarding the protection of
              your personal information. Those rights are:
            </p>
            <ul className="list-disc ml-5 text-white">
              <li>
                The right to access the information we have about you. If you
                wish to access your personal information that we collect, you
                can do so at any time by contacting us using the contact details
                provided below.
              </li>
              <li>
                The right to rectify inaccurate information about you. You can
                correct, update or request deletion of your personal information
                by contacting us using the contact details provided below.
              </li>
              <li>
                The right to object to the processing. When we rely on your
                consent to process your personal information, you may withdraw
                consent at any time by contacting us using the contact details
                provided below. This will not affect the lawfulness of
                processing prior to the withdrawal of your consent.
              </li>
              <li>
                The right to lodge a complaint. You can raise questions or
                complaints to the national Data Protection Agency in your
                country of residence in the event where your rights may have
                been infringed. However, we recommend attempting to reach a
                peaceful resolution of the possible dispute by contacting us
                first.
              </li>
              <li>
                The right to erase any data concerning you. You may demand
                erasure of data without undue delay for legitimate reasons,
                e.g., where data is no longer necessary for the purposes it was
                collected, or where the data has been unlawfully processed.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              11. APPLICATION OF POLICY
            </h2>
            <p className="text-gray-400">
              This Policy was created with the help of the TermsHub.io and the
              Privacy Policy Generator and applies only to the services offered
              by our Company. Our Policy does not apply to services offered by
              other companies or individuals, including products or sites that
              may be displayed to you in search results, sites that may include
              our services, or other sites linked from our Site or Services.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              12. AMENDMENTS
            </h2>
            <p className="text-gray-400">
              Our Policy may change from time to time. We will post any Policy
              changes on our Site, and if the changes are significant, we may
              consider providing a more explicit notice (including, for certain
              services, email notification of Policy changes).
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              13. ACCEPTANCE OF THIS POLICY
            </h2>
            <p className="text-gray-400">
              We assume that all Users of this Site have carefully read this
              document and agree to its contents. If someone does not agree with
              this Policy, they should refrain from using our Site. We reserve
              the right to change our Policy at any time and inform by using the
              way as indicated in Section 12. Continued use of this Site implies
              acceptance of the revised Policy.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              14. FURTHER INFORMATION
            </h2>
            <p className="text-gray-400">
              If you have any further questions regarding the data we collect or
              how we use it, then please feel free to contact us at the details
              as indicated above. Users from EU and EEA can easily contact our
              European representative: Officer contact@getflxart.com.
            </p>
          </div>
        </div>
        <Link
          className="inline-flex btn-sign up text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4"
          to="/"
        >
          Homepage
        </Link>
      </div>
      <footer className="m-4">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link
              to="/"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <span className="logo flex uppercase text-lg">FLXART</span>
            </Link>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium  sm:mb-0 text-gray-400">
              <li>
                <Link to="/Policy" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/Terms" className="hover:underline">
                  Terms
                </Link>
              </li>
            </ul>
            <div className="text-white">
              <a href="#Pricing" className="hover:underline me-4">
                +1 (234) 567-890
              </a>
              <a href="#Pricing" className="hover:underline me-4">
                flxart@gmail.com
              </a>
            </div>
          </div>
          <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <span className="block text-sm  sm:text-center text-gray-400">
            © 2023
            <Link to="/" className="hover:underline">
              FLXART™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
