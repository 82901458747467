import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../redux/slices/auth";
import { motion } from "framer-motion";
import axios from "axios";

export const ForgotPass = () => {
  const checkAuth = useSelector(isAuthSelector);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const isValidEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const sendOTP = async () => {
    if (
      submitClicked &&
      lastRequestTime &&
      Date.now() - lastRequestTime < 60000
    ) {
      setError(
        "Please wait for 1 minute before making another recovery request."
      );
      return;
    }
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setCountdown(60);
      setError("");
      const response = await axios.post(
        `${process.env.REACT_APP_LINK_API_BACKEND}recover_password`,
        { email }
      );
      if (response.status === 404) {
        setError(
          "There is no account associated with this email for password recovery."
        );
      } else {
        setLastRequestTime(Date.now());
        setSuccessMessage(
          "Check your email for password recovery instructions."
        );
        setTimeout(() => {
          setSuccessMessage("");
        }, 10000);
      }
    } catch (error) {
      if (error.request.status === 404) {
        setError(
          "There is no account associated with this email for password recovery."
        );
      } else {
        setError("Failed to send recovery request. Please try again later.");
      }
      setCountdown(0);
    }
  };

  useEffect(() => {
    let intervalId;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.15,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const resetMessages = () => {
    setSuccessMessage("");
  };

  if (checkAuth) {
    return <Navigate to="/Dashboard/generation" />;
  }

  return (
    <section>
      <motion.div
        variants={container}
        animate="visible"
        initial="hidden"
        className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
      >
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <motion.h1
              variants={item}
              className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white"
            >
              Password recovery
            </motion.h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <motion.label
                  variants={item}
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Enter your email
                </motion.label>
                <motion.input
                  variants={item}
                  type="text"
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-none outline-none sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                />
              </div>
              {successMessage && (
                <div style={{ color: "green", marginTop: "10px" }}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.5 }}
                    className="bg-green-500 text-white px-4 py-2 mt-2 rounded"
                  >
                    Check your email for instructions!
                  </motion.div>
                </div>
              )}
              <motion.a
                variants={item}
                onClick={() => {
                  setSubmitClicked(true);
                  sendOTP(email);
                  resetMessages(); // Reset error and success messages
                }}
                className="btn-sign up block cursor-pointer w-full hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
              >
                Submit
              </motion.a>
              {countdown > 0 && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  Please wait for {countdown} seconds before making another
                  recovery request.
                </div>
              )}
              {error && (
                <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
              )}
            </form>
          </div>
        </div>
        <motion.div variants={item}>
          <Link
            to="/"
            className="inline-flex btn-sign up text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4"
          >
            Homepage
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};
