import React, { useContext } from "react";
import { Context } from "../Context";
import { Link, Navigate } from "react-router-dom";
import { isAuthSelector, logout } from "../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function SideBar() {
  const dispatch = useDispatch();
  const { viewBar, setViewBar } = useContext(Context);
  const checkAuth = useSelector(isAuthSelector);
  const onClickLogout = () => {
    dispatch(logout());
    Cookies.remove("webToken");
    <Navigate to="/" />;
  };

  return (
    <div className="bg-sidebar ">
      <div
        className={`fixed top-0 left-0  inset-y-0 right-0 z-50 bg-gray-800 w-full overflow-y-auto px-6 py-6  transition-transform   ${
          viewBar && "-translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between">
          <Link to="/" className="logo flex uppercase text-lg">
            FlxArt
          </Link>

          <a
            onClick={() => setViewBar(!viewBar)}
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </a>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <a
                href="#Pricing"
                onClick={() => setViewBar(!viewBar)}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-gray-900 hover:bg-gray-50 text-center"
              >
                Pricing
              </a>
              <a
                href="#Collection"
                onClick={() => setViewBar(!viewBar)}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-gray-900 hover:bg-gray-50 text-center"
              >
                Collection
              </a>
            </div>
            <div className="py-6">
              {checkAuth ? (
                <div className="flex flex-col">
                  <Link
                    className="-mx-3 block btn-sign rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-gray-900 hover:bg-gray-50 text-center"
                    to="/dashboard/profile"
                  >
                                      My Account
                  </Link>
                  <Link
                    to="/"
                    onClick={onClickLogout}
                    className="btn-logout block cursor-pointer text-white text-center mt-3"
                  >
                    Logout
                  </Link>
                </div>
              ) : (
                <>
                  <Link
                    onClick={() => setViewBar(!viewBar)}
                    to="/Login"
                    className="btn-sign in -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:text-gray-900 hover:bg-gray-50 text-center"
                  >
                    Log In
                  </Link>
                  <Link
                    onClick={() => setViewBar(!viewBar)}
                    to="/SignUp"
                    className="btn-sign up mt-5 -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:text-gray-900 hover:bg-gray-50 text-center"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
