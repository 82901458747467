import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const getImg = createAsyncThunk("auth/getImg", async (params) => {
  const { data } = await axios.post("/api/generationImg", { ...params });
  return data;
});

export const buyPrompts = createAsyncThunk(
  "auth/buyPrompts",
  async (params) => {
    const { data } = await axios.post("/api/auth/login/buy", params);
    return data;
  }
);

const initialState = {
  data: null,
  status: "loading",
};

const generationSlice = createSlice({
  name: "generation",
  initialState,
  //   reducers: {

  //   },
  extraReducers: {
    [getImg.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [getImg.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [getImg.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
    [buyPrompts.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [buyPrompts.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [buyPrompts.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
  },
});

export const generationReducer = generationSlice.reducer;

// export const { logout, getData } = authSlice.actions;
