import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

const textAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

export const Footer = () => {
  return (
    <footer className="m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link
            to="/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <span className="logo flex uppercase text-lg">FLXART</span>
          </Link>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium  sm:mb-0 text-gray-400">
            <li>
              <a href="#generate" className="hover:underline me-4 md:me-6">
                Generate
              </a>
            </li>
            <li>
              <a href="#Pricing" className="hover:underline me-4 md:me-6">
                Pricing
              </a>
            </li>
            <li>
              <a href="#Collection" className="hover:underline me-4 md:me-6">
                Collection
              </a>
            </li>
            <li>
              <Link to="/Policy" className="hover:underline me-4 md:me-6">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/Terms" className="hover:underline">
                Terms
              </Link>
            </li>
          </ul>
          <div>
            <a
              href="+1 (234) 567-890"
              className="hover:underline me-4 text-white"
            >
              +1 (234) 567-890
            </a>
            <a
              href="flxart@gmail.com"
              className="hover:underline me-4 text-white"
            >
              flxart@gmail.com
            </a>
          </div>
        </div>
        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
        <span className="block text-sm  sm:text-center text-gray-400">
          © 2023
          <Link to="/" className="hover:underline">
            FLXART™
          </Link>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
