import { motion } from "framer-motion";
import React, { useContext } from "react";
import { buyPrompts } from "../redux/slices/generation";
import { useDispatch } from "react-redux";
import { Context } from "../Context";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const Price = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData, setPaymentSum } = useContext(Context);
  const [popUpBuy, setPopUpBuy] = React.useState(false);
  const [activePrice, setActivePrice] = React.useState({
    indexPrice: 1,
    price: 11.99,
    amount: 100,
  });
  const [errorPopUp, setErrorPopUp] = React.useState(false);
  const infoPrice = [
    {
      packageName: "Free Package",
      price: "0",
      amount: "10 prompts",
      descr: "Get started with our Free Plan and explore the basics",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
    ,
    {
      packageName: "Basic Package",
      price: "11.99",
      amount: "100 prompts",
      descr:
        "Our $11.99 package offers essential features for those on a budget",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
    {
      packageName: "Intermediate Package",
      price: "29.99",
      amount: "300 prompts",
      descr:
        "Upgrade to our $29.99 package and unlock advanced tools and resources",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
    {
      packageName: "Advanced Package",
      price: "54.99",
      amount: "600 prompts",
      descr:
        "Experience premium performance and features with our $54.99 package",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
    {
      packageName: "Max Package",
      price: "109.99",
      amount: "1200 prompts",
      descr: "Unlock the ultimate experience with our $109.99 package",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
    {
      packageName: "Vip Package",
      price: "199.99",
      amount: "2000 prompts",
      descr: "Experience unparalleled excellence with our $199.00 package",
      // privilege: ["100 prompt", "Lorem, ipsum."],
    },
  ];
  const textAnimation = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };
  const handleBuyPrompts = async (price, indexPrice) => {
    try {
      setPopUpBuy(false);
      await dispatch(buyPrompts({ indexPrice }));
      document.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section id="pricing" className="price mt-9">
        <h1 className="text-center">Buy prompts</h1>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6"
        >
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10  lg:space-y-0">
            {infoPrice.map((item, index) => (
              <motion.div
		    key={index}
                custom={index}
                variants={textAnimation}
                className="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow border-gray-600 xl:p-8 bg-gray-800 text-white"
              >
                <h3 className="mb-4 text-2xl font-semibold">
                  {item.packageName}
                </h3>
                <p className="font-light  sm:text-lg text-gray-400">
                  {item.descr}
                </p>
                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-5xl font-extrabold">
                    ${item.price}
                  </span>
                </div>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3 justify-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5  text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-lg">{item.amount}</span>
                  </li>
                </ul>

                {index != 0 ? (
                  <Link
                    to="/PaymentPrompts"
                    onClick={
                      () => {
                        setPaymentSum(index);
                      }
                      // setPopUpBuy(true);
                      // setActivePrice({
                      //   indexPrice: index,
                      //   price: item.price,
                      //   amount: item.amount,
                      // });
                    }
                    className=" btn-sign up bg-primary-600 cursor-pointer hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                  >
                    Buy
                  </Link>
                ) : null}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>
      {popUpBuy && (
        <div
          className="relative z-10  "
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 transition-opacity"></div>

          <div className="fixed   shadow inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative border border-gray-600 transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-600 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 12.75L11.25 15L15 9.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        className="text-base font-semibold leading-6  text-white"
                        id="modal-title"
                      >
                        Confirm write-off
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-300">
                          Your card will be charged ${activePrice.price} and
                          {activePrice.amount} tips will be credited.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {errorPopUp && (
                  <p className="text-red-500 text-center">
                    Not enough money in the account
                  </p>
                )}
                <div className="bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <a
                    onClick={() => {
                      handleBuyPrompts(
                        activePrice.price,
                        activePrice.indexPrice - 1
                      );
                    }}
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto mb-5"
                  >
                    Good
                  </a>
                  <a
                    onClick={() => {
                      setPopUpBuy(false);
                      setErrorPopUp(false);
                    }}
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white   sm:ml-3 sm:w-auto mb-5"
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
