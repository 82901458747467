import { Link } from "react-router-dom";

export const Terms = () => {
  return (
    <>
      <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
        <div className="grid gap-4 md:gap-8">
          <h1 className="text-2xl font-bold md:text-4xl text-white">
            TERMS OF SERVICES
          </h1>
          <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
            PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT
            CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND
            REMEDIES.
          </h2>
          <p className="mb-5 text-gray-400">
            Last Revised: 2023-10-28 10:26:45
          </p>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              1. OVERVIEW
            </h2>

            <p className="mb-5 text-gray-400">
              This Terms of Service Agreement ("Agreement") is entered into by
              and between ==FLXART==, registered address ==business address==,
              GB, GA ("Company") and you, and is made effective as of the date
              of your use of this website https://getflxart.com/ ("Site") or the
              date of electronic acceptance.
            </p>
            <p className="mb-5 text-gray-400">
              This Agreement sets forth the general terms and conditions of your
              use of the https://getflxart.com/ as well as the products and/or
              services purchased or accessed through this Site (the "Services").
              Whether you are simply browsing or using this Site or purchase
              Services, your use of this Site and your electronic acceptance of
              this Agreement signifies that you have read, understand,
              acknowledge and agree to be bound by this Agreement our Privacy
              policy. The terms "we," "us" or "our" shall refer to Company. The
              terms "you," "your," "User" or "customer" shall refer to any
              individual or entity who accepts this Agreement, uses our Site,
              has access or uses the Services. Nothing in this Agreement shall
              be deemed to confer any third-party rights or benefits.
            </p>
            <p className="mb-5 text-gray-400">
              Company may, in its sole and absolute discretion, change or modify
              this Agreement, and any policies or agreements which are
              incorporated herein, at any time, and such changes or
              modifications shall be effective immediately upon posting to this
              Site. Your use of this Site or the Services after such changes or
              modifications have been made shall constitute your acceptance of
              this Agreement as last revised.
            </p>
            <p className="mb-5 text-gray-400">
              IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AS LAST REVISED,
              DO NOT USE (OR CONTINUE TO USE) THIS SITE OR THE SERVICES.
            </p>
            <p className="mb-5 text-gray-400">
              IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AS LAST REVISED,
              DO NOT USE (OR CONTINUE TO USE) THIS SITE OR THE SERVICES.
            </p>
          </div>

          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              2. ELIGIBILITY
            </h2>

            <p className="text-gray-400">
              This Site and the Services are available only to Users who can
              form legally binding contracts under applicable law. By using this
              Site or the Services, you represent and warrant that you are (i)
              at least eighteen (18) years of age, (ii) otherwise recognized as
              being able to form legally binding contracts under applicable law,
              and (iii) are not a person barred from purchasing or receiving the
              Services found under the laws of the United Kingdom or other
              applicable jurisdiction.
            </p>
            <p className="text-gray-400">
              If you are entering into this Agreement on behalf of a company or
              any corporate entity, you represent and warrant that you have the
              legal authority to bind such corporate entity to the terms and
              conditions contained in this Agreement, in which case the terms
              "you," "your," "User" or "customer" shall refer to such corporate
              entity. If, after your electronic acceptance of this Agreement,
              Company finds that you do not have the legal authority to bind
              such corporate entity, you will be personally responsible for the
              obligations contained in this Agreement.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              3. RULES OF USER CONDUCT
            </h2>

            <p className="text-gray-400">
              By using this Site, You acknowledge and agree that:
            </p>
            <ul className="list-disc ml-5 text-white">
              <li>
                Your use of this Site, including any content you submit, will
                comply with this Agreement and all applicable local, state,
                national and international laws, rules and regulations.
              </li>
            </ul>
            <p className="text-gray-400">
              You will not use this Site in a manner that:
            </p>
            <ul className="list-disc ml-5 text-white">
              <li>Is illegal, or promotes or encourages illegal activity;</li>
              <li>
                Promotes, encourages or engages in child pornography or the
                exploitation of children;
              </li>
              <li>
                Promotes, encourages or engages in terrorism, violence against
                people, animals, or property;
              </li>
              <li>
                Promotes, encourages or engages in any spam or other unsolicited
                bulk email, or computer or network hacking or cracking;
              </li>
              <li>
                Infringes on the intellectual property rights of another User or
                any other person or entity;
              </li>
              <li>
                Violates the privacy or publicity rights of another User or any
                other person or entity, or breaches any duty of confidentiality
                that you owe to another User or any other person or entity;
              </li>
              <li>Interferes with the operation of this Site;</li>
              <li>
                Contains or installs any viruses, worms, bugs, Trojan horses,
                Cryptocurrency Miners or other code, files or programs designed
                to, or capable of, using many resources, disrupting, damaging,
                or limiting the functionality of any software or hardware.
              </li>
            </ul>
            <p className="text-gray-400">You will not:</p>
            <ul className="list-disc ml-5 text-white">
              <li>
                Copy or distribute in any medium any part of this Site, except
                where expressly authorized by Company,
              </li>
              <li>
                Copy or duplicate this Terms of Services agreement, which was
                created with the help of the TermsHub.io and the Terms of Use
                Generator ,
              </li>
              <li>
                Modify or alter any part of this Site or any of its related
                technologies,
              </li>
              <li>
                Access Companies Content (as defined below) or User Content
                through any technology or means other than through this Site
                itself.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              4. INTELLECTUAL PROPERTY
            </h2>
            <p className="text-gray-400">
              In addition to the general rules above, the provisions in this
              Section apply specifically to your use of Companies Content posted
              to Site. Companies Content on this Site, including without
              limitation the text, software, scripts, source code, API,
              graphics, photos, sounds, music, videos and interactive features
              and the trademarks, service marks and logos contained therein
              ("Companies Content"), are owned by or licensed to ==business name
              UK== in perpetuity, and are subject to copyright, trademark,
              and/or patent protection.
            </p>
            <p className="text-gray-400">
              Companies Content is provided to you "as is", "as available" and
              "with all faults" for your information and personal,
              non-commercial use only and may not be downloaded, copied,
              reproduced, distributed, transmitted, broadcast, displayed, sold,
              licensed, or otherwise exploited for any purposes whatsoever
              without the express prior written consent of Company. No right or
              license under any copyright, trademark, patent, or other
              proprietary right or license is granted by this Agreement.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              5. LINKS TO THIRD-PARTY WEBSITES
            </h2>
            <p className="text-gray-400">
              This Site may contain links to third-party websites that are not
              owned or controlled by Company. Company assumes no responsibility
              for the content, terms and conditions, privacy policies, or
              practices of any third-party websites. In addition, Company does
              not censor or edit the content of any third-party websites. By
              using this Site you expressly release Company from any and all
              liability arising from your use of any third-party website.
              Accordingly, Company encourages you to be aware when you leave
              this Site and to review the terms and conditions, privacy
              policies, and other governing documents of each other website that
              you may visit.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              6. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES
            </h2>
            <p className="text-gray-400">
              YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE
              SHALL BE AT YOUR OWN RISK AND THAT THIS SITE ARE PROVIDED "AS IS",
              "AS AVAILABLE" AND "WITH ALL FAULTS". COMPANY, ITS OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, DISCLAIM ALL WARRANTIES, STATUTORY,
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
              WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. COMPANY, ITS OFFICERS, DIRECTORS,
              EMPLOYEES, AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT
              (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE
              ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH
              HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND/OR
              (III) THE SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH
              HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND
              COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE SAME.
            </p>
            <p className="text-gray-400">
              IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL
              OR WRITTEN INFORMATION OR ADVICE PROVIDED BY COMPANY, ITS
              OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, AND THIRD-PARTY SERVICE
              PROVIDERS WILL (I) CONSTITUTE LEGAL OR FINANCIAL ADVICE OR (II)
              CREATE A WARRANTY OF ANY KIND WITH RESPECT TO THIS SITE OR THE
              SERVICES FOUND AT THIS SITE, AND USERS SHOULD NOT RELY ON ANY SUCH
              INFORMATION OR ADVICE.
            </p>
            <p className="text-gray-400">
              THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL
              APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, and shall survive
              any termination or expiration of this Agreement or your use of
              this Site or the Services found at this Site.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              7. LIMITATION OF LIABILITY
            </h2>
            <p className="text-gray-400">
              IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, AND ALL THIRD PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR
              ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
              SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING
              ANY DAMAGES THAT MAY RESULT FROM (I) THE ACCURACY, COMPLETENESS,
              OR CONTENT OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR
              CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER
              ADVERTISING OR OTHERWISE) TO THIS SITE, (III) THE SERVICES FOUND
              AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER
              ADVERTISING OR OTHERWISE) TO THIS SITE, (IV) PERSONAL INJURY OR
              PROPERTY DAMAGE OF ANY NATURE WHATSOEVER, (V) THIRD-PARTY CONDUCT
              OF ANY NATURE WHATSOEVER, (VI) ANY INTERRUPTION OR CESSATION OF
              SERVICES TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH
              HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VII)
              ANY VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH MAY BE
              TRANSMITTED TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH
              HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VIII)
              ANY USER CONTENT OR CONTENT THAT IS DEFAMATORY, HARASSING,
              ABUSIVE, HARMFUL TO MINORS OR ANY PROTECTED className,
              PORNOGRAPHIC, "X-RATED", OBSCENE OR OTHERWISE OBJECTIONABLE,
              AND/OR (IX) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
              YOUR USE OF THIS SITE OR THE SERVICES FOUND AT THIS SITE, WHETHER
              BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE
              THEORY, AND WHETHER OR NOT COMPANY IS ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES.
            </p>
            <p className="text-gray-400">
              IN ADDITION, You SPECIFICALLY ACKNOWLEDGE AND agree that any cause
              of action arising out of or related to this Site or the Services
              found at this Site must be commenced within one (1) year after the
              cause of action accrues, otherwise such cause of action shall be
              permanently barred.
            </p>
            <p className="text-gray-400">
              THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
              EXTENT PERMITTED BY LAW, AND shall survive any termination or
              expiration of this Agreement or your use of this Site or the
              Services found at this Site.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              8. INDEMNITY
            </h2>
            <p className="text-gray-400">
              You agree to protect, defend, indemnify and hold harmless Company
              and its officers, directors, employees, agents from and against
              any and all claims, demands, costs, expenses, losses, liabilities
              and damages of every kind and nature (including, without
              limitation, reasonable attorneys’ fees) imposed upon or incurred
              by Company directly or indirectly arising from (i) your use of and
              access to this Site; (ii) your violation of any provision of this
              Agreement or the policies or agreements which are incorporated
              herein; and/or (iii) your violation of any third-party right,
              including without limitation any intellectual property or other
              proprietary right. The indemnification obligations under this
              section shall survive any termination or expiration of this
              Agreement or your use of this Site or the Services found at this
              Site.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              9. DATA TRANSFER
            </h2>
            <p className="text-gray-400">
              If you are visiting this Site from a country other than the
              country in which our servers are located, your communications with
              us may result in the transfer of information across international
              boundaries. By visiting this Site and communicating electronically
              with us, you consent to such transfers.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              10. AVAILABILITY OF WEBSITE
            </h2>
            <p className="text-gray-400">
              Subject to the terms and conditions of this Agreement and our
              policies, we shall use commercially reasonable efforts to attempt
              to provide this Site on a 24/7 basis. You acknowledge and agree
              that from time to time this Site may be inaccessible for any
              reason including, but not limited to, periodic maintenance,
              repairs or replacements that we undertake from time to time, or
              other causes beyond our control including, but not limited to,
              interruption or failure of telecommunication or digital
              transmission links or other failures.
            </p>
            <p className="text-gray-400">
              You acknowledge and agree that we have no control over the
              availability of this Site on a continuous or uninterrupted basis,
              and that we assume no liability to you or any other party with
              regard thereto.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              11. DISCONTINUED SERVICES
            </h2>
            <p className="text-gray-400">
              Company reserves the right to cease offering or providing any of
              the Services at any time, for any or no reason, and without prior
              notice. Although Company makes great effort to maximize the
              lifespan of all its Services, there are times when a Service we
              offer will be discontinued. If that is the case, that product or
              service will no longer be supported by Company. In such case,
              Company will either offer a comparable Service for you to migrate
              to or a refund. Company will not be liable to you or any third
              party for any modification, suspension, or discontinuance of any
              of the Services we may offer or facilitate access to.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              12. FEES AND PAYMENTS
            </h2>
            <ul className="list-disc ml-5 text-white">
              <li>
                You acknowledge and agree that your payment will be charged and
                processed by FLXART.
              </li>
              <li>
                You agree to pay any and all prices and fees due for Services
                purchased or obtained at this Site at the time you order the
                Services.
              </li>
              <li>
                Company expressly reserves the right to change or modify its
                prices and fees at any time, and such changes or modifications
                shall be posted online at this Site and effective immediately
                without the need for further notice to you.
              </li>
              <li>
                Refund Policy: for products and services eligible for a refund,
                you may request a full refund within 30 days of purchase
                ("Refund Period"). The refund will be processed in 30 days from
                the request. In no event will you be eligible for more than one
                refund of the same service.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              13. NO THIRD-PARTY BENEFICIARIES
            </h2>
            <p className="text-gray-400">
              Nothing in this Agreement shall be deemed to confer any
              third-party rights or benefits.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              14. COMPLIANCE WITH LOCAL LAWS
            </h2>
            <p className="text-gray-400">
              Company makes no representation or warranty that the content
              available on this Site is appropriate in every country or
              jurisdiction, and access to this Site from countries or
              jurisdictions where its content is illegal is prohibited. Users
              who choose to access this Site are responsible for compliance with
              all local laws, rules, and regulations.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              15. GOVERNING LAW
            </h2>
            <p className="text-gray-400">
              This Agreement and any dispute or claim arising out of or in
              connection with it or its subject matter or formation shall be
              governed by and construed in accordance with the laws of the
              United Kingdom, GA, to the exclusion of conflict of law rules.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              16. DISPUTE RESOLUTION
            </h2>
            <p className="text-gray-400">
              Any controversy or claim arising out of or relating to these Terms
              of Services will be settled by binding arbitration. Any such
              controversy or claim must be arbitrated on an individual basis and
              must not be consolidated in any arbitration with any claim or
              controversy of any other party. The arbitration must be conducted
              in the United Kingdom, GA, and judgment on the arbitration award
              may be entered into any court having jurisdiction thereof.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              17. TITLES AND HEADINGS
            </h2>
            <p className="text-gray-400">
              The titles and headings of this Agreement are for convenience and
              ease of reference only and shall not be utilized in any way to
              construe or interpret the agreement of the parties as otherwise
              set forth herein.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              18. SEVERABILITY
            </h2>
            <p className="text-gray-400">
              Each covenant and agreement in this Agreement shall be construed
              for all purposes to be a separate and independent covenant or
              agreement. If a court of competent jurisdiction holds any
              provision (or portion of a provision) of this Agreement to be
              illegal, invalid, or otherwise unenforceable, the remaining
              provisions (or portions of provisions) of this Agreement shall not
              be affected thereby and shall be found to be valid and enforceable
              to the fullest extent permitted by law.
            </p>
          </div>
          <div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-white">
              19. CONTACT INFORMATION
            </h2>
            <p className="text-gray-400">
              If you have any questions about this Agreement, please contact us
              by email or regular mail at the following address:
            </p>
            <ul className="list-disc ml-5 text-white">
              <li>FLXART</li>
              <li>Mr. Walter C. Brown 49 Featherstone Street</li>
              <li>GB, GA</li>
              <li>flxart@gmail.com</li>
            </ul>
          </div>
        </div>
        <Link
          className="inline-flex btn-sign up text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-900 my-4"
          to="/"
        >
          Homepage
        </Link>
      </div>
      <footer className="m-4">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link
              to="/"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <span className="logo flex uppercase text-lg">FLXART</span>
            </Link>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium  sm:mb-0 text-gray-400">
              <li>
                <Link to="/Policy" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/Terms" className="hover:underline">
                  Terms
                </Link>
              </li>
            </ul>
            <div className="text-white">
              <a href="#Pricing" className="hover:underline me-4">
                +1 (234) 567-890
              </a>
              <a href="#Pricing" className="hover:underline me-4">
                flxart@gmail.com
              </a>
            </div>
          </div>
          <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <span className="block text-sm  sm:text-center text-gray-400">
            © 2023
            <Link to="/" className="hover:underline">
              FLXART™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
